.control-panel {
	&-nav {
		.nav-link {
			padding-left: 0;
			color: @title_color;

			i {
				font-size: small;
				padding-right: 5px;
				vertical-align: middle;
				opacity: .7;
				text-align: center;
				width: 20px;
				display: inline-block;
			}

			&:hover,
			&.active {
				i {
					opacity: 1;
				}
			}

			&.active {
				color: @brand-primary;
			}
		}

		.nav-item:first-child .nav-link {
			padding-top: 0;
		}
	}

	&-wrapper {
		h3 {
			margin-bottom: 15px;
		}

		.json-preview {
			max-height: 150px;
			position: relative;
			padding: 5px;
			border: 1px solid #ddd;
			border-radius: 4px;
			background-color: #f5f5f5;
			line-height: 1;
			display: inline-block;
			width: 100%;
			overflow: hidden;
			
			&-btn {
				position: absolute;
				top: 0;
				right: 0;
				border: 1px solid #ddd;
				border-top: 0;
				border-right: 0;
				border-bottom-left-radius: 4px;
				text-transform: lowercase;
				padding: 5px 10px;
				font-weight: bold;
				font-size: x-small;
				color: #666;
			}
		}

		code {
			font-size: x-small;
			display: block;
			overflow: auto;
			line-height: 1.5;
			max-height: 140px;
		}
	}
}

@import "vigilante";