.vigilante {
  &-wrapper {
    .filter_result {
      padding-top: 0;
      justify-content: left;
      gap: 30px;
    }
  }

  &-alert {
    &-list {
      &-item {
        display: block;
        margin-left: 2em;
        position: relative;

        &-count {
          background: #eeeeee;
          border-radius: 100px;
          padding: 15px;
          position: absolute;
          top: 0;
          left: -2em;
          width: 3em;
          height: 3em;
          text-align: center;
          line-height: 3em;
        }

        &-details {
          padding: 0 1.5em;
          display: flex;
          flex-direction: column;
          gap: 5px;
        }
      }
    }
  }
}