input,select,textarea {
    border:1px solid #DDDDDD;
    background: @white;
    border-radius: @btn-radius;
    color:#333333;
    padding: 10px 17px;
    padding-left:10px;
    font-size: 0.875rem;
    max-width: 100%;
    height: 50px;

    &[disabled] {
        background-color: #eee;
        opacity: .8;
    }
}

textarea {
    height: 100px;
}

label {
    margin-bottom: 10px;
}


input[type=checkbox],input[type=radio] {
    height: 20px;
    width: 20px;
    border:1px solid #DDDDDD;
    background: @white;
    border-radius: @btn-radius;
    display: none;
}


input.checkbox {
    position: absolute;
    margin: 0 auto;
    left: 0;
    right: 0;
    bottom: 0;
    opacity: 0;
    cursor: pointer;
    z-index:1;

    &:checked ~ .custom_check, &:checked ~ .custom_radio  {
        border-color: @brand-secondary;
        background:fade(@brand-secondary,8%);
        i {
            visibility: visible;
        }  
    }
}



.custom_check,
.custom_radio {
    height:20px;
    width:20px;
    border-radius: @btn-radius;
    border:1px solid #DDDDDD;
    display: block;
    margin:0;
    background: @white;
    cursor: pointer;

    i {
        font-size: 13px;
        position: relative;
        left: 2px;
        top: 2px;
        color:@brand-secondary;
        display: block;
        visibility: hidden;
    }

    &-label {
        margin-bottom: 0;
    }
}

.custom_radio {
    border-radius: 50px;
}

span.select2-container--default {
    width: 100% !important;

    [class^="icon-"]:before, 
    [class*=" icon-"]:before {
        vertical-align: middle;
        margin-right: 3px;
    }

    .select2-selection--single,
    .select2-selection--multiple {
        border-color: #ddd;

        &::after {
            content: "";
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 6px 5.5px 0 5.5px;;
            border-color: #999 transparent transparent transparent;
            position: absolute;
            right: 10px;
            top: 42%;
            pointer-events: none;
        }

        .select2-selection__rendered {
            padding: 0 9px;
        }

        .select2-selection__choice {
            border:1px solid @brand-secondary;
            background:fade(@brand-secondary,8%);
            border-radius: @btn-radius;
            color:#333333;
            padding:6px 8px;
            font-size: 14px;
            line-height: 16px;
            display: inline-flex;
            flex-direction: row-reverse;
            margin-top: 9px;
            margin-bottom: 0;
        }
        
        .select2-selection__choice__remove {
            margin: 0;
            padding-left: 10px;
            color: @brand-secondary;
        }

        .select2-selection__arrow {
            display: none;
        }
    }

    .select2-selection--single {
        height: 50px;

        .select2-selection__rendered {
            line-height: 50px;
        }
    }

    .select2-search--inline {
        .select2-search__field {
            height: 36px;
        }
    }

    &.select2-container--focus {
        .select2-selection--multiple {
            border-color: #ddd;
        }
    }

    .select2-results {
        > .select2-results__options {
            font-size: 14px;
        }
    }
}

.tagfield {
    .tagbox {
        position: relative;
        width:100%;
        min-height: 50px;
        padding:10px;
        border-radius: @btn-radius;
        border:1px solid #DDDDDD;
        background: @white;
        display: inline-block;

        .search {
            height: 30px;
            position: absolute;
            width: 85%;
            z-index: 9;
            top: calc(40% - 13px);
            display: none;
        }

        .arrow-down {
            cursor: pointer;
            position: absolute;
            right: 13px;
            top: 43%;
            z-index: 10;
        }

        span {
            border:1px solid @brand-secondary;
            background:fade(@brand-secondary,8%);
            border-radius: @btn-radius;
            color:#333333;
            padding:7px 9px;
            font-size: 0.875rem;
            padding-right: 5px;
            margin-bottom: 5px;
            display: inline-block;

            a {
                position: relative;
                left: 5px;
                padding-right: 10px;

                &:hover {
                    text-decoration: none;
                }
                i {
                    font-size: 0.5rem;
                }
            }
        }
    }
}

.social_medias_checkbox {
    .text {
        // position: relative;
        // top:-1px;

        i {
            position: relative;
            top:2px;
            padding: 0 5px;
        }
    }
}

.new_scan_type_list {
    a {
        padding: 40px;
        color: @brand-secondary;
        width: 250px;

        &:hover {
            text-decoration: none;
        }

        i {
            font-size: 4.5rem;
            display: block;
            text-align: center;
        }

        span {
            font-size: 0.875rem;
            display: block;
            margin-top: -20px;
        }

        span.detail {
            padding-top:18px;
            font-size: 0.75rem;
            color:#999999;
        }
    }

    a.active {
        background: fade(@brand-secondary,8%);
        border-color:@brand-secondary;
    }
}

.switch {
    position: relative;
    display: inline-block;
    width: 60px;
    height: 28px;
    display: block;
    margin-top:10px;
    margin-bottom: 0;
    
    input {
        opacity: 0;
        width: 0;
        height: 0;
    }

    input:checked + .slider {
        background-color: #2699FB;
    }

    input:focus + .slider {
        box-shadow: 0 0 1px #2196F3;
    }

    input:checked + .slider:before {
        -webkit-transform: translateX(31px);
        -ms-transform: translateX(31px);
        transform: translateX(31px);
      }

    .slider.round {
        border-radius: 34px;

        &:before {
            border-radius: 34px;
        }
    }

    .slider {
        position: absolute;
        cursor: pointer;
        top: 0;
        left: 0;
        right: 0;
        bottom: 0;
        background-color: #ccc;
        -webkit-transition: .4s;
        transition: .4s;

        &:before {
            position: absolute;
            content: "";
            height: 21px;
            width: 21px;
            left: 4px;
            bottom: 4px;
            background-color: white;
            -webkit-transition: .4s;
            transition: .4s;
        }
      }
}

.switch.small {
    width: 40px;
    height: 18px;

    input:checked + .slider:before {
        -webkit-transform: translateX(20px);
        -ms-transform: translateX(20px);
        transform: translateX(20px);
      }

    .slider {
        &:before {
            height: 12px;
            width: 12px;
            top:2.5px;
        }
    }
}
  
.one-column {
    .form-control {
        label{
            width:100%;
            display: block;
        }

        label.switch.small {
            width: 40px;
            height: 18px;
        }

        textarea,input,select {
            width:100% !important;
        }
    }
}


.daterangepicker-wrapper {
    position: relative;

    .icon-small-calendar {
        position: absolute;
        right: 13px;
        top: 13px;
        pointer-events: none;
        color: #999;
        font-size: 21px;
    }
}

.daterangepicker {
    font-family: inherit !important;
}