.navbar {
    .logo-link{
        background: #fff;
        padding: 7px;
    }

    .navbar-brand {
        padding-left: 15px;

        img {
            display: block;
        }

        span {
            display: block;
            margin-top:3px;
            font-size:0.75rem;
            font-weight: 400;
        }
    }

    .navbar-nav {
        margin-left:80px;

        li.nav-item {
            padding-right: 45px;
        }
    }

    .nav-icon {
        li.nav-item {
            padding: 0;

            .nav-link {
                padding: 0 15px;
            }
        }
    }
}

.navbar-primary {
    background: @brand-primary;
    display: block;

    a {
        color:@white;
    }

    .navbar-nav {
        margin: 0;

        .nav-link {
            // font-size:0.875rem;
            font-weight: 500;
            padding-left: 0;
        }
    }
}