body {
    position: relative;
    padding-bottom: 35px; // footer
    min-height: 100vh;

    &.cke_editable {
        margin: 0 !important;
        padding: 15px;
    }
}

[data-aload] { background-image: none !important; }

.spacer-20{ margin : 20px 0 }

.hidden {
    display: none;
}

h1 {
    font-size:@title_size;
    color:@title_color;
}

.btn {
    border-radius: @btn-radius;
    font-family: Rubik, sans-serif;
    font-weight: 500;
    text-transform: uppercase;
    line-height: 1.5;

    &:not(.btn-sm) {
        padding:7px 1.5rem;
        font-size:12px;
    }

    &.btn-sm {
        font-size: smaller;
        line-height: 1;
    }
  
    i {
        position: relative;
        top:2px;
        left:-2px;
    }

    &.dropdown-toggle-split {
        padding-left: .5rem;
        padding-right: .5rem;
    }
}

.btn-secondary {
    background:@brand-secondary;
    color:@white;
    font-family: rubik;
    border:1px solid #2699FB;
    
    &:hover {
        background:@brand-secondary;
        color:@white;
        border:1px solid #2699FB;
    }
}

.btn-outline {
    color:@brand-secondary;
    border:1px solid @brand-secondary;
    font-weight: 500;
    background: @white;
}

.btn-outline-red {
    border-color:#BA1E1E;
    color:#BA1E1E;

    &:hover {
        color:#BA1E1E;
    }
}

.btn-larger {
    padding:10px 30px;
    font-size: 14px;
}

.btn-smaller {
    padding: .75em 1.5em;
    font-size: 11px;
    line-height: 1;
    font-weight: 400;
}

.btn-squared {
    border-width: 1px;
    border-style: solid;
    border-radius: @btn-radius;
    padding:12px 12px 3px;

    &:hover {
        text-decoration: none;
    }
}

.btn-squared-red {
    color:#CB5555;
    border-color: #CB5555;

    &:hover {
        color:#CB5555;
    }
} 

.icon-circled {
    height: 60px;
    width: 60px;
    border-width: 1px;
    border-style: solid;
    border-radius: 150px;
    position: relative;
    // float:left;
    display: inline-block;
    text-align: center;
    line-height: 60px;
    margin-right: 15px;

    i {
        position: relative;
        top:5px;
        font-size: 1.625rem;
    }
}

.icon-circled-blue {
    border-color:@brand-secondary;
    background: fade(@brand-secondary,8%);

    i {
        color:@brand-secondary;
    }
}

.icon-circled-green {
    border-color:#26FB91;
    background: fade(#26FB91,8%);

    i {
        color:#26FB91;
    }
}

.icon-circled-yellow {
    border-color:#FBD226;
    background: fade(#FBD226,8%);

    i {
        color:#FBD226;
    }
}

.icon-circled-red {
    border-color:#BA1E1E;
    background: fade(#BA1E1E,8%);

    i {
        color:#BA1E1E;
    }
}

.outlined {
    border:1px solid #DDDDDD;
    padding:15px 0;
    border-radius: @btn-radius;
    margin-top:15px;
}

.b-dashed {
    border-style: dashed !important;
    border-color:#DDDDDD;
}

.b-dashed.b-left {
    border-left-width: 1px;
}

.body-spacing {
    margin:20px 0;
}

.p-relative {
    position: relative;
    // top:-1px;
}

.p-left-0 {
    padding-left:0 !important;
}

.arrow-down {
    width: 0;
    height: 0;
    border-left: 7px solid transparent;
    border-right: 7px solid transparent;
    border-top: 7px solid #999999;
}

.btns {
    a {
        &:last-child {
            margin-left:10px;
        }    
    }
}



.entries_box {
    padding: 4px;
    border-radius: 4px;
    background: #BA1E1E;
    font-family: rubik;
    font-weight: 800;
    color: #fff;
    font-size: 0.75rem;
    position: relative;
    left: 10px;
    top: -3px;
}

.link-secondary {
    font-size: 0.875rem;
    text-decoration: underline;
}

.c-padding-left-m18 {
    left:-18px;
}

.w-45  {
    width:45%;
}

.default-margin-top {
    margin-top:20px;
}


dl {
    display: flex;
    flex-wrap: wrap;

    dt {
        flex-basis: 40%;
        padding-right: 10px;
        opacity: .8;
        margin-bottom: 5px;
    }

    dd {
        flex-basis: 60%;
        max-width: 60%;
        word-wrap: break-word;
        word-break: break-word;
        margin-bottom: 5px;
    }
}


table.table {
    border-collapse: collapse;

    thead {
        border-bottom:1px dashed #DDDDDD;

        tr {
            th {
                border:none;
                font-size: 0.875rem;;
                font-weight: 800;
            }


            th.actions {
                // text-align: right;
                // padding-right:150px;
            }

            .ordered {
                color:@brand-secondary;
            }
        }
    }

    tbody {
        tr {
            border-bottom:1px dashed #DDDDDD;
            td {
                border:none;
                vertical-align: middle;
                padding:12px 12px 10px;
                white-space: nowrap;

                &.large {
                    font-size: 1.125rem;
                }

                &.wrap {
                    white-space: initial;
                }

                small {
                    font-size: smaller;
                }
            }

            td.actions {
                a.btn:not(:last-child) {
                    margin-right: 12px;
                }
            }

            .icons {
                [class^="icon"] { 
                    padding-right: 10px;
                    color: #ddd;

                    &.isin {
                        color: @brand-secondary;
                    }
                }
                [class^="icn"] { 
                    padding-right: 10px;
                    color: #ddd;

                    &.isin {
                        color: @brand-secondary;
                    }
                }
                
                .isin > [class*="icon"] {
                    color: @brand-secondary;
                }
                .isin > [class*="icn"] {
                    color: @brand-secondary;
                }
            }

            td.small {
                font-size: 0.9rem;
                color:#666;
            }
        }
    }
}

.table-font-14 {
     tr td {
        font-size: 0.875rem !important;
    }

    thead tr th {
        font-size: 0.875rem !important;
    }
}

.thead_underscore {
    tr th {
        text-decoration: underline;
    }
}

footer {
    background: #F5F5F5;
    border-top:1px solid #DDDDDD;
    font-size:0.75rem;
    color:#999999;

    position: absolute;
    bottom: 0;
    width: 100%;
   

    .navbar-nav {
        display: flex;
        flex-direction: row;
        align-items: center;

        .nav-item {
            margin-left:55px;

            &:first-child {
                margin-left:0;
            }

            .nav-link {
                color:#999999;
            }
        }
    }
}

.toast {
    background-color: #fff;

    &-header {
        padding: .5rem .75rem;
        background-color: transparent;
        color: inherit;

        .close {
            font-size: 12px;
            font-weight: normal;
            text-shadow: none;
        }

        i {
            margin-right: 5px;

            &::before {
                vertical-align: middle;
            }
        }
    }

    &-body {
        padding: .5rem .75rem;
    }

    &-success {
        background-color: #d4edda;
        border-color: #c3e6cb;
        color: #155724;
    }

    &-error {
        background-color: #f8d7da;
        border-color: #f5c6cb;
        color: #721c24;
    }

    &-wrapper {
        position: fixed; 
        top: 85px; 
        left: 15px; 
        z-index: 2;
    }
}


.feed-list {

    a.comum-link {
        font-size:0.875rem;
        text-decoration: underline;

        i {
            display: inline-block;
            text-decoration: none;
            position:relative;
            top:3px;
        }
    }

    .input-action {
        .a {
            padding-bottom:8px;
            padding-top: 6px;
            font-size: 11px;
            color:#999999;
            line-height: 1;
            visibility: hidden;

            &.visible {
                visibility: visible;
            }
        }
        
        .double {
            padding-top:8px;
            font-size: 16px;
            color: #999999;
            line-height: 0;
            opacity: 0;
        }
    }

    .feed-box.new {
        background: fade(@brand-secondary,8%);

        .input-action {
            i.double {
                opacity: 1;
            }
        }
    }

    .feed-box {
        width: 100%;
        margin: 0 auto;
        border-bottom:1px dashed #DDDDDD;
        padding-left: 15px;
        // padding-right: 15px;

        padding-bottom: 15px;
        padding-top: 15px;

        &:first-child {
            margin-top:0;
            padding-top: 15px;
        }

        &:last-child {
            border:none;
        }

        .image {
            border-radius: 4px;
            margin-right: 15px;
            background-position: top center;
            background-repeat: no-repeat;
            background-size: cover;
            cursor: pointer;
            flex-basis: 40%;
            height: 70%;
        }
    
        .info {
            width:100%;
            font-size:0.875rem;
            display:grid;

            a {
                word-break: break-all;
            }
            
            h5 {
                color:@brand-secondary;
                font-weight: normal;
                font-family: Karla, sans-serif;

                i {
                    top: 1px;
                    position: relative;
                }
            }
    
            time { 
                padding:5px 0;
                a {
                    color:#999999;
                }
            }
    
            .description {
                color:#333333;
                max-height: 100px;
                overflow: auto;
                padding-right: 5px;
                word-break: break-word;

                strong {
                    opacity: .8;
                }
            }

            .description::-webkit-scrollbar-thumb {
                background-color:#444;
                border-radius: 10px;
               
            }

            .description::-webkit-scrollbar {
                width: 5px;
                background: #ccc;
              }
              > div{
                  margin-bottom: 5px;
                  img{
                      display: inline-block;
                      vertical-align: middle;
                      margin-right: 5px;
                      height:9%;
                  }
              }
        }
    
        .claimant {
            ul {
                list-style: none;
                display:flex;
                flex-direction: column;
                margin-bottom:13px;
                li {
                    font-size: 14px;
                    white-space: normal;
                    word-break: break-all;
                    span {
                        width: 80px;
                        display: inline-block;
                        font-weight: bold;
                        opacity: .8;
                    }
                }
            }
        }
    }
}

.claimants-list {
    .claimant-box {
        width: 100%;
        margin: 0 auto;
        border-bottom:1px dashed #DDDDDD;
        padding-bottom: 15px;
        margin-top: 15px;

        &:first-child {
            margin-top:0;
        }

        &:last-child {
            border:none;
            padding-bottom:0;
        }

        h5 {
            color:#333333;
            font-size: 18px;
            
            .social-media {
                color:@brand-secondary;

                i {
                    top: 2px;
                    position: relative;
                }
            }
        }

        time {
            font-size: 14px;
            color:#999999;
            padding:5px 0;
        }
    }
}

.filter_result {
    padding: 30px 0;
    font-size: 0.875rem;

    width: 100%;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .tab-content & {
        padding: 15px 0;
    }

    select,
    input {
        display: inline-block;
        margin-left: 15px;

        &[name="sortby"] {
            width: 160px;
        }

        &[name="perpage"] {
            width: 75px;
        }
    }

    label {
        margin: 0;
    }
}

.main-filter {
    background: #F5F5F5;
    margin-left:-15px;
    margin-right: -15px;
    height: 100%;
    min-width: 200px;

    &-form{
        padding: 0 30px 30px;
        background: #a19fa0;
    }

    #request {
        padding: 0 30px 30px;
    }

    input,select {
        width:100%;
    }
    
    h2 {
        font-size: 2rem;
        color:#333333;
        padding: 33px 0 30px;
        font-weight: 800;
    }

    h3 {
        font-size: 1.7rem;
        color:#333333;
        padding: 33px 0 30px;
        font-weight: 800;
    }

    .form-group {
        margin-bottom:30px;
        .text {
            margin-left:9px;
            font-size: 0.875rem;
        }

        
        .checkboxspace {
            display: flex;
            margin-bottom: 10px;
        }

        .p-relative {
            top:2px;
        }
    }

    .view-claimant-filters-link {
        &::before {
            content: "Hide ";
        }
        
        &.collapsed {
            &::before {
                content: "View ";
            }
        }
    }

    &-collapse {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: #ddd;
        color: #999;
        font-size: 12px;
        cursor: pointer;
        padding: 4px 0 4px 30px;
        line-height: 1;

        i {
            font-size: 6px;
            display: inline-block;
            position: relative;
            top: -1px;
        }

        &-closed i {
            transform: rotate(180deg);
        }

        .col-3 & {
            &-opened {
                display: block;
            }

            &-closed {
                display: none;
            }
        }

        .col-1 & {
            &-opened {
                display: none;
            }

            &-closed {
                display: block;
            }
        }
    }

    .col-1 &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: linear-gradient(90deg, rgba(245,245,245,0.1) 0%, rgba(245,245,245,1) 100%);
        border-right: 1px solid #eee;
    }
}

.filter {
    background: #F5F5F5;
    margin-left:-15px;
    margin-right: -15px;
    padding: 0 30px 30px;
    height: 100%;
    min-width: 200px;

    input,select {
        width:100%;
    }
    
    h2 {
        font-size: 2rem;
        color:#333333;
        padding: 33px 0 30px;
        font-weight: 800;
    }

    h3 {
        font-size: 1.7rem;
        color:#333333;
        padding: 33px 0 30px;
        font-weight: 800;
    }

    .form-group {
        margin-bottom:30px;
        .text {
            margin-left:9px;
            font-size: 0.875rem;
        }

        
        .checkboxspace {
            display: flex;
            margin-bottom: 10px;
        }

        .p-relative {
            top:2px;
        }
    }

    .view-claimant-filters-link {
        &::before {
            content: "Hide ";
        }
        
        &.collapsed {
            &::before {
                content: "View ";
            }
        }
    }

    &-collapse {
        position: absolute;
        top: 0;
        left: 0;
        right: 0;
        z-index: 1;
        background: #ddd;
        color: #999;
        font-size: 12px;
        cursor: pointer;
        padding: 4px 0 4px 30px;
        line-height: 1;

        i {
            font-size: 6px;
            display: inline-block;
            position: relative;
            top: -1px;
        }

        &-closed i {
            transform: rotate(180deg);
        }

        .col-3 & {
            &-opened {
                display: block;
            }

            &-closed {
                display: none;
            }
        }

        .col-1 & {
            &-opened {
                display: none;
            }

            &-closed {
                display: block;
            }
        }
    }

    .col-1 &::after {
        content: "";
        display: block;
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        z-index: 0;
        background: linear-gradient(90deg, rgba(245,245,245,0.1) 0%, rgba(245,245,245,1) 100%);
        border-right: 1px solid #eee;
    }
}

.table-footer {
    padding: 15px;

    .p-relative {
        float:left;
    }

    .text {
        margin-left:10px;
    }

    .check-all-wrapper {
        display: flex;
        align-items: center;
        cursor: pointer;
        margin: 0;
    }

    &-button-group {
        display: flex;

        .btn {
            margin-left: 10px;
        }
    }

    &:last-child {
        margin-bottom: 30px;
    }
}

.table-footer.full {
    margin-left:-15px;
}

.pagination {
    margin-bottom:30px;
    .col {
        &:first-child {
            padding-left:0;
        }
    }
    ul {
        li {
            display: inline;
            margin-right: 26px;

            a {
                font-size: 0.875rem;
                color:@brand-secondary;
            }
        }

        li.disabled {
            a {
                color:#999999;
            }
        }

        li.active {
           a {
            color:#333333;
           }
        }

        &:last-child li:last-child {
            margin-right: 0;
        }
    }

    .text {
        padding-right:12px;
    }

    select {
        width: 75px;
    }
}

.queue_list {
    margin-bottom:30px;
    .queue_actions {
        padding:10px 30px;

        li {
            border-bottom:1px dashed #DDDDDD;
            padding-bottom: 15px;
            padding-top:15px;

            &:first-child {
                padding-top:0;
            }

            &:last-child{
                padding-bottom: 0;
                padding-top:30px;
                border-bottom: none;
            }
        }
    }

    .queue_desc {
        margin-left: 75px;
        margin-top: 7px;
        p {
            width:700px;
            font-size:1.125rem;
            color:#333333;

            .t-underline {
                text-decoration: underline;
            }
        }

        .time {
            span {
                font-size: 0.875rem;
                color:#999999;
    
                &:first-child{
                    margin-right: 72px;
                }

            }
        }
        

      
    }
}

.subtitle {
    font-size: 1.357rem;
    font-weight: 500;
    margin-bottom: 15px;
}

.subtitle2 {
    font-size: 1.125rem;
    color: #333333;
    font-size:karla;
    font-weight: 400;
    margin-top:15px;

}

.upload {
    padding:30px 0;
    width:100%;
    h2 {
        font-size: 1.357rem;
        font-weight: 500;

    }

    .example {
        font-size: 0.875rem;
        margin-top:15px;
    }

    .result {
        margin-top:15px;

        .btn-secondary {
            width: 151px;
            display: flex;
            justify-content: center;
            margin:0 auto;
        }

        .no-border {
            border:none;
        }
    }
    
}

#drop_zone {
    margin-top:15px;
    width: 100%;
    height: 90px;
    background: #DDDDDD;
    border:1px solid #F5F5F5;
    border-radius: @btn-radius;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding:30px;
    p {
        color:#999999;
        font-family: rubik;
        font-size: 1.125rem;
        margin:0 auto;
    }
    
}

#drop_zone.with-file {
    justify-content: initial;
    .file {
        justify-content: space-between;
        display: flex;
        width:100%;

        .filename {
            color:@brand-secondary;
            i {
                position: relative;
                top:7px;
                font-size:1.875rem;
                padding-right: 9px;
            }
            span {
                font-size: 0.875rem;
                color:#333333;

                .percent {
                    color:@brand-secondary;
                    padding-left:8px;
                }
            }
        }

        a {
            display: flex;
            justify-content: center;
            align-items: center;
        }
    }
}

.new_scan_form {
    padding:30px 0;
    width:100%;
    h4 {
        font-size:18px;
        font-family: karla;
        font-weight: 400;
        padding:15px 0;
    }

    .custom_header {
        padding: 0;
        margin-top: -15px;
    }

}

.default_form {

    .default-space {
        margin-top:10px;
    }

    .subtitle {
        margin-top:15px;
    }

    input,select,textarea {
        width:100%;
    }

    button[type="submit"] {
        margin-top:40px;
        margin-bottom:20px;
    }

    .form-group {
        max-width:100%;

        textarea {
            height: 147px;
        }
    }

    .picture_preview {
        display: flex;
        align-items: center;

        .preview {
            flex-shrink: 0;
            background: @white;
            border:1px solid #DDDDDD;
            border-radius: 4px;
            height: 169px;
            width: 169px;
            overflow: hidden;

            i {
                font-size:78px;
                color:#DDDDDD;
                position: relative;
                top:10px;
            }

            .img,
            .image {
                position: relative;                    
                height: 169px;
                width: 169px;
                object-fit: cover;
                background-size: cover;
                background-position: center;
            }
        }

        .btn {
            margin-left: 15px;
            // font-family: rubik;
            // padding: 0 20px;
            // height: 30px;
            // width:auto;
            // color:@brand-secondary;
            // padding-top:5px;

            &#change {
                display: none;
            }
        }

        input[type="file"] {
            width: 0.1px;
            height: 0.1px;
            opacity: 0;
            overflow: hidden;
            position: absolute;
            z-index: -1;
        }
       
    }
}

.scan_claimant {
    margin-top:15px;
    .row {
        .outlined-title {
            font-size:1.125rem;
            color:#333333;
        }
        .outlined {
            padding:15px;
            margin-top:10px;

            .picture {
                .img {
                    background: #F5F5F5;
                    border-radius: 4px;
                    height: 100px;
                    width:100px;
                }
            }

            .info {
                margin-top:15px;

                h2 {
                    margin-bottom:15px;
                }

                
                p {
                    font-size:0.875rem;
                    color:#333333;
                }
            }

            .switch {
                margin-top:0;
            }

            .social_media {
                margin-bottom:15px;

                &:last-child {
                    margin-bottom:0;
                }
                p {
                    font-size: 14px;
                    color:#999999;

                    i {
                        position: relative;
                        top:3px;
                        right:5px;
                    }
                }
            }

            .social_media.active {
                p {
                    color:@brand-secondary;
                }
            }
        }
    }

    button[type=submit] {
        margin-top:15px;
    }

    .claim-section {
        input {
            
        }
    }
}

.claimant-feed {
    .back-link {
        margin-top: 30px;
        margin-bottom: 10px;
        display: block;
    }

    .claimant-box {
        font-size: 0.875rem;
        color:#333333;

        &-info {
            margin-top: 0;
            padding-top: 30px;
            padding-bottom: 30px;
            padding-right: 15px;

            .image {
                height: calc(100% + 60px);
                min-height: 250px;
                background: #F5F5F5;
                border-radius:4px 0 0 4px;
                margin-top: -30px;
                margin-bottom: -30px;
                margin-left: -15px;
                background-position: center;
                background-size: cover;
            }
        }

        .name {
            font-size: 2rem;
        }

        .desc {
            padding-top:15px;
        }

        .injury,
        .account {
            margin-top: 15px;

            > span {
                font-weight: bold;
                opacity: .8;
            }

            > p {
                max-height: 130px;
                overflow: auto;
            }
        }

        .account {
            > p {
                overflow: visible;
            }
        }

        .infos {
            list-style: none;
            line-height: 1.8;
            margin-top: 15px;

            li {
                display: block;
                width: 100%;
                height: 25px;
                white-space: nowrap;
            } 

            span {
                display: inline-block;

                &:first-child {
                    padding-right:15px;
                    width:120px;
                    font-weight: bold;
                    opacity: .8;
                }

                a {
                    text-decoration: underline;

                    i {
                        display: inline-block;
                        text-decoration: none;
                        position: relative;
                        top: 2px;
                        left: 2px;
                    }
                }
            }
        }

        .actions {
            a {
                padding: 6px 21px;
                font-size:12px;
                font-family: rubik;
                display: flex;
                justify-content: center;
                align-items: center;
            }
        }

        .social_medias {
            margin-top:40px;

            .social_media {
                a {
                    font-size: 0.875rem;
                    color:#DDDDDD;
                    
                    i {
                        position: relative;
                        top:2px;
                        padding-right: 10px;
                    }

                    &:hover {
                        text-decoration:none;
                    }
                }
            }
            .social_media.active {
                a {
                    color:@brand-secondary;
                }
            }
        }

        .add-account {
            margin-top: 30px;
            margin-bottom: 30px;
        }

    }
}

.tab {
    margin-top:30px;

    .nav-tabs {
        font-size:0.875rem;
        font-family: rubik;
        border-bottom:2px solid #F5F5F5;
        .nav-item {
            .nav-link {
                border:none;
                padding:15px 30px;
                color:#333333;
                position: relative;
                top: 1px;


                &:hover {
                    border:none;
                }
            } 

            .nav-link.active {
                color:@brand-secondary;
                border-bottom:2px solid @brand-secondary;
            }
        }
    }
}

#viewableby {
    padding:30px 0;

    .text {
        font-size: 0.875rem;
        padding:30px 7px;

        p {
            padding-right: 20px;
        }
    }
}

.claimant-background-content,
body.cke_editable {
    margin-top: 15px;

    img{
        max-width: 600px;
    }

    p {
        margin-bottom: 1em;
    }

    h2 {
        font-size: 3em;
        margin-bottom: .5em;
    }

    h3 {
        font-size: 2em;
        margin-bottom: .5em;
    }

    h4 {
        margin-bottom: .1em;
    }

    ul {
        padding-left: 1.1em;
        margin-bottom: 1em;
    }

    blockquote {
        overflow: hidden;
        padding-right: 1.5em;
        padding-left: 1.5em;
        margin-left: 0;
        font-style: italic;
        border-left: 5px solid #ccc;
        margin-bottom: 1em;

        p {
            margin: 0;
        }
    }
}

.manual-entry {
    &-platform {
        margin: 0;

        label {
            margin: 0;

            [class*="icon-"] {
                vertical-align: middle;
            }

            [class*="icn-"] {
                vertical-align: middle;
            }
        }

        input {
            margin-top: 10px;
            width: 100%;
            display: block;
        }

        form {
            display: none;

            button.btn[type="submit"] {
                margin-top: 10px;
                float: right;
            }
        }
    }
}

.claimant_detected {
    padding-bottom:50px;
    .info {
        padding-right: 50px;
        h4 {
            font-size: 1.125rem;
            font-family: karla;
            color:#333333;
            font-weight: 800;
            span {
                font-size: 0.875rem;
                font-weight: 400;
            }
        }
    }

    .claimant {
        width:220px;
    }

    .btn {
        margin-top:30px;
    }
}

.screenshot_options {
    padding-bottom:50px;
    .picture {
        margin-top:30px;
        max-width: 100%;
    }

    .btn {
        margin-top:30px;
    }
}

.account {
    .switch {
        margin-top:0;
    }

    .table {
        margin-top: 15px;
        
        th.actions {
            // padding-right: 110px;
        }
        
    }

    .btn:not([type="submit"]) {
        padding:5px 15px;
        font-size:12px;
    }
}


.modal-content {
    border-color:#DDDDDD;
}

.modal-header {
    border:none;
    padding:30px;

    &-buttons {
        &-wrapper {
            display: flex;
        }
    }
}

.modal-body {
    font-size: 0.875rem;
    padding:0 30px 30px;

    > *:last-child {
        margin-bottom: 0;
    }
}

.modal-footer {
    border:none;
    padding: 0 30px 30px;

    &:empty {
        display: none;
    }
}

.modal-default {
    overflow: auto;

    h3 {
        font-size: 1.5rem;

        small {
            font-size: small;
            color: #999;
            padding-left: 1em;
        }
    }

    .btn-outline:not(.original) {
        background: #fff;
        border:1px solid #999999;
        color:#999999;
    }

    .modal-dialog {
        //max-width: 800px;
    }

    .modal-content {
        border-color:#DDDDDD;
    }

    .modal-header {
        border:none;
        padding:30px 32px 30px;
    }

    .modal-body {
        font-size: 0.875rem;
        padding:0 30px 30px;

        h2 {
            margin-bottom:15px;
            font-size: 24px;
            color:#333333;
            font-weight: 500;
        }

        time {
            font-size: 14px;
            display: block;
            margin-bottom:15px;
            
            a {
                color:#999999;
            }
        }

        input[type="text"],
        input[type="number"],
        input[type="email"],
        input[type="password"],
        select,
        textarea {
            width: 100%;
        }

        button[type="submit"] {
            margin-top: 20px;
        }

      
        .data {
            
            .form-control {
                border:none;   
                padding:0;        
                margin-top: 30px;

                &:first-child {
                    margin-top:0;
                }
            }
            label {
                font-size: 0.875rem;
                color:#333333;
            }

            textarea {
                height: 140px;
                width: 350px;
                background: #fff;
                border-color:#DDDDDD;
                border-radius: 4px;
            }
        }
        
        .info {
            margin-left: 15px;
            padding-left:30px;
            border-left: 1px dashed #ddd;

            label {
                margin-bottom:10px;
            }
            ul {
                list-style: none;
                li {
                    margin-bottom:5px;
                    span {
                        width:130px;
                        display: block;
                        line-break: normal;
                        float:left;
                    }
                    max-width: 100%;
                    word-break: break-all;
                    clear: both;
                    a {
                        display: flex;
                        word-break: break-all;
                    }
                }
            }
        }

        .medias {
            margin-top:30px;

            label {
                clear: both;
                margin-bottom:10px;
            }
            ul {
                list-style: none;

                li {
                    // float:left;
                    // margin-right: 30px;
                    height: 0;
                    padding-bottom: calc(25% - 30px);

                    &:nth-child(1n+5) {
                        margin-top: 30px;
                    }

                    .img,
                    .add {
                        width: calc(100% - 30px);
                        height: 100%; 
                        border: 1px solid #DDDDDD;
                        border-radius: 4px;
                        position: absolute;
                        background-size: cover;

                        .delete,
                        .zoom {
                            width:20px;
                            height:20px;
                            position: absolute;
                            z-index: 2;
                            top:2px;
                            right:2px;
                            cursor: pointer;
                            text-decoration: none;

                            background: #BA1E1E;
                            border:1px solid #BA1E1E;
                            border-radius: 4px;
                            opacity: 1;

                            i {
                                color:#fff;
                                line-height: 0.8;
                            }
                        }

                        .delete {
                            i {
                                font-size:8px;
                            }
                        }

                        .zoom {
                            top: auto;
                            bottom: 2px;
                            background-color: @brand-secondary;
                            border-color: @brand-secondary;
                            text-align: center;

                            i {
                                font-size: 12px;
                            }
                        }

                        video {
                            width: 100%;
                            height: 100%;
                            pointer-events: none;
                        }
                    }

                    .add {
                        color: #2699FB;
                        border-color: #2699FB;
                        cursor: pointer;
                        font-size: 12px;
                        font-family: rubik;
                        font-weight: 500;
                    }

                    input {
                        opacity: 0;
                        height: 0;
                        width: 0;
                        position: absolute;
                    }
                }
            }
        }
    }

    .modal-footer {
        border:none;
    }
}

#default_modal {
    transform: opacity .3s;
    opacity: 1;
    
    .has-ekko-lightbox & {
        opacity: .0;
    }
}

.ekko-lightbox {
    .modal-body {
        padding: 0;
    }

    .ekko-lightbox-nav-overlay a {
        color: #333;
    }
}

.modal-delete {

    .modal-header {
        padding-bottom:0;

        i.icon-delete {
            font-size: 80px;
            color:#BA1E1E;
            line-height: 1.2;
        }

        .btn[data-dismiss="modal"] {
            align-self: flex-end;
        }
    }

    .modal-content {
        h2 {
            text-align: center;
            font-size: 1.5rem;
            font-weight: 500;
            margin-bottom:15px;
        }
    
        p {
            font-size: 0.875rem;
            color:#333333;
            margin-bottom:15px;
            text-align: center;
        }

        ul {
            list-style: none;
            
            li {
                margin-bottom:5px;
                display: flex;
                justify-content: center;
                span {
                    width:80px;
                    display: block;
                    float:left;
                }
            }
        }
    }
}

tr.loading {
    background: #e3e3e3;
    opacity: 0.5;
}

.modal-body.scroll  {
    max-height: 550px;
    overflow-y: scroll;
}

#claimant_modal {
    .pagination {
        .pag_links {
            float: left;
            position: absolute;
            left: 30px;
        }
    }
}

.login {
    padding-top: 50px;
    padding-bottom: 20px;

    h1 {
        text-align: center;
    }

    form {
        width: 100%;
        max-width: 400px;
        margin: auto;
        padding: 0 15px;

        label {
            display: block;
            padding-bottom:5px;
            font-size:1em;
        }
        input {
            width:100%;
            margin-bottom:15px;
        }

        .btn {
            margin-top:20px;
            display: block;
            width: 100%;
            line-height: 2.9;
            margin-bottom: 15px;
        }

        p {
            text-align: center;
            text-decoration: underline;
        }
    }
}

.table-filter {
    margin-top:35px;
    margin-bottom:25px;

    div {
        p.float-left {
            padding-right: 20px;
        }

        p {
            line-height: 3;
        }
    }
}

.view-feed {
    a.sm {
        font-size: 18px;
        font-family: Karla, Rubik, sans-serif;
        display: block;

        i {
            vertical-align: sub;
        }
    }

    &-content {
        &:extend( .feed-list .feed-box .info .description );

        max-height: unset;
        padding: 0;
    }

    .form-control {

        h5 {
            i {color:@brand-secondary};
        }
    }

    &-actions {
        margin-right: 60px;
        display: flex;

        > * {
            margin-left: 10px;
        }
    }

    &-carousel {
        .carousel-item {
            i {
                opacity: 0;
                transform: opacity .3s;
                position: absolute;
                top: 50%;
                left: 50%;
                font-size: 24px;
                color: #333;
                transform: translate(-50%, -50%);
            }

            &:hover i {
               opacity: 1; 
            }
        }

        li {
            list-style: none;
            margin-top: 30px;
            position: relative;

            img {
                border: 1px solid #ddd;
                border-radius: 4px;
                overflow: hidden;
            }
        }

        .carousel-control-next, 
        .carousel-control-prev {
            opacity: .8;
            color: #333;
            font-size: 24px;

            &:focus,
            &:hover {
                opacity: 1;
                color: #333;
            }
        }
    }
}


.quick-capture {
    [class^="icon-"]:before, [class*=" icon-"]:before {
        vertical-align: middle;
    }
    [class^="icn-"]:before, [class*=" icn-"]:before {
        vertical-align: middle;
    }
}

#claimant_injury{
    height: 100px;
}
