@charset "UTF-8";

@font-face {
  font-family: "fraudsniffr-2";
  src:url("../fonts/fraudsniffr-2/fraudsniffr-2.eot");
  src:url("../fonts/fraudsniffr-2/fraudsniffr-2.eot?#iefix") format("embedded-opentype"),
    url("../fonts/fraudsniffr-2/fraudsniffr-2.woff") format("woff"),
    url("../fonts/fraudsniffr-2/fraudsniffr-2.ttf") format("truetype"),
    url("../fonts/fraudsniffr-2/fraudsniffr-2.svg#fraudsniffr-2") format("svg");
  font-weight: normal;
  font-style: normal;

}

[data-icon]:before {
  font-family: "fraudsniffr-2" !important;
  content: attr(data-icon);
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

[class^="icon-"]:before,
[class*=" icon-"]:before {
  font-family: "fraudsniffr-2" !important;
  font-style: normal !important;
  font-weight: normal !important;
  font-variant: normal !important;
  text-transform: none !important;
  speak: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.icon-account:before {
  content: "\61";
}
.icon-arrow-left:before {
  content: "\63";
}
.icon-arrow-right:before {
  content: "\64";
}
.icon-autoget:before {
  content: "\65";
}
.icon-cancel:before {
  content: "\66";
}
.icon-carret:before {
  content: "\67";
}
.icon-check:before {
  content: "\68";
}
.icon-comment:before {
  content: "\69";
}
.icon-delete:before {
  content: "\6a";
}
.icon-edit:before {
  content: "\6b";
}
.icon-email:before {
  content: "\6c";
}
.icon-facebook:before {
  content: "\6e";
}
.icon-file-csv:before {
  content: "\6f";
}
.icon-file-doc:before {
  content: "\70";
}
.icon-file-pdf:before {
  content: "\71";
}
.icon-instagram:before {
  content: "\72";
}
.icon-linkedin:before {
  content: "\73";
}
.icon-linkexternal:before {
  content: "\74";
}
.icon-notifications:before {
  content: "\75";
}
.icon-exclamation:before {
  content: "\76";
}
.icon-printer:before {
  content: "\77";
}
.icon-read:before {
  content: "\78";
}
.icon-reload:before {
  content: "\79";
}
.icon-sand-clock:before {
  content: "\7a";
}
.icon-search:before {
  content: "\41";
}
.icon-small-calendar:before {
  content: "\42";
}
.icon-text:before {
  content: "\43";
}
.icon-fraudsniffr:before {
  content: "\45";
}
.icon-upload:before {
  content: "\46";
}
.icon-www:before {
  content: "\47";
}
/* .icon-twitter:before {
  content: "\44";
} */
.icon-logout:before {
  content: "\6d";
}
.icon-zoom-in:before {
  content: "\48";
}
.icon-control-panel:before {
  content: "\49";
}
.icon-add-user:before {
  content: "\4a";
}
.icon-tik-tok:before {
  content: "\62";
}
.icon-eye:before {
  content: "\4b";
}
