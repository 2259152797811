@import 'variables';
@import 'form';
@import 'components';
@import 'navbar'; 
@import 'responsive';
@import 'control-panel';

.companyLogo {
  height: 54px;
  width: 267px;
}

#addCompanyLogo{
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

#navbarCompanyLogo{
  height: auto;
  width: 267px;
}


input[type="color"] {
  -webkit-appearance: none;
  border: none;
}
input[type="color"]::-webkit-color-swatch-wrapper {
  padding: 0;
}
input[type="color"]::-webkit-color-swatch {
  border: none;
}


#companyColorPicker{
  position: relative;
  top: 1px;
  left: 1px;
  height: 58px;
  width: 68px;
}

#companyColorPickerContainer{
  height: 60px !important;
  width: 70px !important;
  max-width: 70px !important;
  background-color: #865e3e;
  border-bottom-left-radius: 3px;
  border-top-left-radius: 3px;
}

.brandBorder{
  border-color: #cccccc;
  border-radius: 3px;
  border-width: thin;
  border-style: solid;
}

.brandWidth{
  max-width: 780px;
}

.maxheight400{
  max-height: 400px !important;
}

.textAreaHeight {
  height: 80px;
}
.modal-default{
  padding: 0 10px !important;
}
.scan-pg{
  .default_form{
    .form-group{
      max-width: unset;
    }
  }
}

// took from css/fraudsniffr-2-font.css
// that file is auto generated, would get overwritten
.isin-filtercolor{
  filter: invert(43%) sepia(74%) saturate(901%) hue-rotate(178deg) brightness(103%) contrast(97%);
}
.isin-filtercolor-none *{
  filter: unset;
}
.physical-surveillance.isin-filtercolor-none {
  filter :invert(97%) sepia(0%) saturate(0%) hue-rotate(170deg) brightness(95%) contrast(86%) opacity(0.5);
}
label > .isin-filtercolor, span.text>.isin-filtercolor{
  filter: unset;
}