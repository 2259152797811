@media (max-width:1385px){
    .feed-list {
        .feed-box {
            .claimant {
                .btn {
                    padding:.375rem .75rem;
                    margin:0 auto;
                    display: block;
                }
            }
        }
    }
    .main-sec{
        .claimant-feed{
            .claimant-box{
                .infos{
                    li{
                        height: auto;
                        white-space: normal;
                    }
                }
                .actions{
                    a{
                        padding: 6px 14px;
                    }
                }
            }
        }
        .filter_result{
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            label{
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                span{
                    white-space: nowrap;
                }
            }
        }
        .main-social-tbl{
            .filter_result{
                label {
                    margin: 4px 0;
                }
            }
        }
    }
    .new_scan_type_list{
        -ms-flex-wrap: wrap;
        flex-wrap: wrap;
    }
}

@media (max-width:1199px){
    .navbar{
        .logo-link{
            background: #fff;
            padding: 7px;
        }
        .navbar-brand{
            padding-left: 0;
            #navbarCompanyLogo {
                width: 220px !important;
            }
        }
    }
    .b-dashed.b-left {
        border-top-width: 1px;
        border-left-width: 0px;
    }
    .modal-default{
        .modal-body{
            .info{
                &.view-info{
                    padding-left: 15px;
                    border-top: 1px dashed #ddd;
                    border-left: none;
                }
            }
        }
    }
    .scan-pg, .quick-capture, .control-panel-content{
        h1 {
            font-size: 2.1rem;
        }
    }
    .account{
        .filter_result{
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
    }
    .main-sec{
        .claimant-feed{
            .tab-content{
                #feed{
                    .feed-list{
                        .b-dashed.b-left {
                            border-top-width: 0px;
                            border-left-width: 1px;
                        }
                    }
                }
            }
        }
        .main-social-tbl{
            table{
                tr{
                    td{
                        dl{
                            -webkit-box-orient: vertical;
                            -webkit-box-direction: normal;
                            -ms-flex-direction: column;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width:991px){
    .btn-larger {
        padding: 8px 24px;
    }
    .navbar{
        .col-lg-9{
            position: unset;
            .navbar-toggler {
                float: right;
                position: absolute;
                top: 22px;
                z-index: 11;
                padding-right: 0;
                right: 15px;
                .icon-bar {
                    width: 22px;
                    transition: all .2s;
                    margin: 4px 0;
                    height: 2px;
                    background: #fff;
                    display: flex;
                    transition: all .4s linear;
                    &.top-bar {
                        transform: rotate(45deg);
                        transform-origin: 10% 10%;
                    }
                    &.middle-bar {
                        opacity: 0;
                        width: 18px;
                    }
                    &.bottom-bar {
                        transform: rotate(-45deg);
                        transform-origin: 10% 90%;
                    }
                }
                &.collapsed .top-bar {
                    transform: rotate(0);
                }
                &.collapsed .middle-bar{
                    opacity: 1;
                }
                &.collapsed .bottom-bar {
                    transform: rotate(0);
                }
                &:focus{
                    outline: none;
                }
            }
            .nav-icon{
                li{
                    &.nav-item{
                        padding: 0.4rem 0;
                        .nav-link{
                            padding: 0;
                        }
                    }
                }
            }
            .filter_result {
                padding: 20px 0;
            }
        }
    } 
    .main-filter{
        #request {
            padding: 0 20px 20px;
        }  
        h3 {
            font-size: 1.5rem;
            padding-bottom: 20px;   
            padding-top: 20px;  
        }  
        .main-filter-form {
            padding: 0 20px 20px;
            h2{
                font-size: 1.7rem;
                padding: 25px 0 25px;
            }
        } 
        
    }
    .filter{
        padding: 0 20px 20px;
        h2{
            padding-bottom: 20px;  
            font-size: 1.7rem;
        }
    } 
    .container-fluid{
        .table-responsive{
            table{
                tbody{
                    tr {
                        td{
                            &.large {
                                font-size: 1rem;
                            }
                        }
                    }
                }
            }
        }
    }
    .main-sec{
        .claimant-feed{
            padding-left: 0;  
            padding-right: 0;
            .claimant-box{
                .infos{
                    li{
                        span{
                            word-break: break-word;
                        }
                    }
                }
                h2{
                    &.name{
                        font-size: 1.6rem;
                    }
                }
                .tab{
                    .nav-tabs{
                        .nav-item{
                            .nav-link{
                                padding: 10px 12px;
                            }
                        }
                    }
                }
                .table-footer-button-group{
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    .btn{
                        padding: 7px 14px;
                        margin-top: 2px;
                        margin-bottom: 2px;
                    }
                }
                .claimant-box-info{
                    padding: 15px;
                    .container-fluid{
                        padding-left: 0;
                        padding-right: 0;
                    }
                    .image{
                        border-radius: 4px;
                        margin: 0 0 10px;
                        background-position: top;
                        background-repeat: no-repeat;
                        max-width: 180px;
                        height: 180px;
                    }
                }
            }
            
        }
        table{
            &.table{
                thead{
                    tr{
                        th{
                            white-space: nowrap;
                        }
                    }
                }
            }
        }
        .main-social-tbl{
            .container-fluid{
                padding-left: 0;
                padding-right: 0;
                .tab{
                    .nav-tabs{
                        .nav-item{
                            .nav-link{
                                padding: 10px 15px;
                            }
                        }
                    }
                }
            }
        }
    }
    .modal-default{
        .modal-header{
            padding: 20px 15px 20px;
            h3{
                font-size: 1.2rem;
            }
        }
        .modal-body{
            padding: 0 15px 25px;
            h3{
                font-size: 1.2rem;
            }
        }
    }
    .account{
        .filter_result{
            label{
                margin: 2px 0;
                display: -webkit-box;
                display: -ms-flexbox;
                display: flex;
                -webkit-box-align: center;
                -ms-flex-align: center;
                align-items: center;
                input{
                    width: 100%;
                }
            }
        }
        .outlined{
            &.table-footer{
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            }
        }
    }
    .scan-pg, .quick-capture, .login, .control-panel-content{
        h1 {
            font-size: 1.9rem;
        }
    }
}

@media (max-width:767px){
    .main-sec{
        .col-3.flr-sidebar{
            position: absolute;
            left: -250px;
            z-index: 11;
            max-width: unset;
            width: 250px;
            overflow: unset !important;
            min-height: calc(100vh - 78px);
            height: calc(100% - 78px);
            .filter-collapse{
                left: 250px;
                padding: 7px 0 7px 10px;
                width: 40px;
                position: absolute;
                border-radius: 0 15px 15px 0;
                background-color: #F5F5F5;
                i{
                    font-size: 1rem;
                    display: inline-flex;
                    margin-top: 2px;
                }
            }
        }
        .col-1.flr-sidebar{
            position: absolute;
            left: 0px;
            z-index: 11;
            width: 250px;
            max-width: unset;
            overflow: unset !important;
            min-height: calc(100vh - 78px);
            height: calc(100% - 78px);
            .filter-collapse{
                left: 250px;
                padding: 7px 0 7px 10px;
                width: 40px;
                position: absolute;
                border-radius: 0 15px 15px 0;
                background-color: #F5F5F5;
                i{
                    font-size: 1rem;
                    display: inline-flex;
                    margin-top: 2px;
                }
            }
            .main-filter{
                &::after{
                    display: none;
                }
            }
            .filter{
                &::after{
                    display: none;
                } 
            }
        }
        .col-9, .col-11{
            -ms-flex: 0 0 100%;
            flex: 0 0 100%;
            max-width: 100%;
        }
        .filter_result{
            padding: 20px 0;
            select, input{
                height: 40px;
            }
        }
        .feed-list{
            .feed-box{
                padding-right: 15px;
                .d-flex{
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    -webkit-box-align: center;
                    -ms-flex-align: center;
                    align-items: center;
                    -webkit-box-pack: center;
                    -ms-flex-pack: center;
                    justify-content: center;
                    text-align: center;
                    .custom_check-label{
                        left: auto;
                    }
                    .image{
                        min-width: 110px;
                        min-height: 110px;
                        flex-basis: auto;
                        margin: 0 0 10px;
                    }
                }
            }
        }
        .claimant-feed{
            padding-left: 0;  
            padding-right: 0;
            .claimant-box{
                .lead{
                    font-size: 1.1rem;
                }
                .claimant-background-content{
                    a{
                        word-break: break-all;
                    }
                }
                .infos{
                    span{
                        display: unset;
                        white-space: normal;
                    }
                }
            }
            .tab-content{
                #feed{
                    .feed-list{
                        .b-dashed.b-left {
                            border-top-width: 1px;
                            border-left-width: 0px;
                        }
                    }
                }
            }
        }
        .filter{
            h2{
                font-size: 1.4rem;
                padding-top: 20px;
            }
        }
        &.quick-capture{
            .claimant-box{
                .claimant-box-info{
                    .container-fluid{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .new_scan_type_list{
        a{
           margin: 10px auto; 
        }
    }
    footer{
        bottom: auto;
        padding: 10px 0;
        .container-fluid{
            -webkit-box-orient: vertical;
            -webkit-box-direction: normal;
            -ms-flex-direction: column;
            flex-direction: column;
            .navbar-nav{
                -webkit-box-orient: vertical;
                -webkit-box-direction: normal;
                -ms-flex-direction: column;
                flex-direction: column;
                text-align: center;
                width: 100%;
                .nav-item{
                    margin-left: 0;
                }
            }
        }
    }
    .modal-default{
        .modal-dialog {
            max-width: unset;
            margin: 0.5rem auto;
            .info{
                padding-left: 15px;
                border-top: 1px dashed #ddd;
                border-left: none;
            }
        }
        .modal-header-buttons-wrapper{
            -webkit-box-orient: vertical;
            -webkit-box-direction: reverse;
            -ms-flex-direction: column-reverse;
            flex-direction: column-reverse;
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            -webkit-box-align: end;
            -ms-flex-align: end;
            align-items: flex-end;
            .modal-header-buttons{
                -ms-flex-preferred-size: 100%;
                flex-basis: 100%;
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                .view-feed-actions{
                    margin-right: 0;
                    display: -webkit-box;
                    display: -ms-flexbox;
                    display: flex;
                    -ms-flex-wrap: wrap;
                    flex-wrap: wrap;
                    -webkit-box-pack: end;
                    -ms-flex-pack: end;
                    justify-content: flex-end;
                    .btn{
                        margin: 2px;
                    }
                }
            }
        }
        .modal-footer{
            padding: 0 20px 20px;
            .pagination {
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
                width: 100%;
                .pag_links{
                    float: none !important;;
                    position: relative !important;;
                    left: auto !important;;
                }
            }
        }
        .capture-pagi{
            ul{
                -ms-flex-wrap: wrap;
                flex-wrap: wrap;
            }
        }
    }
    #new_scan_form{
        .no-padding {
            padding: 0 15px !important;
        }
    }
    .account{
        .outlined{
            &.table-footer{
                .btn{
                    margin: 2px;
                }
            }
        }
        .pagination{
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
        }
        .tab{
            &.container-fluid{
                padding-left: 0;
                padding-right: 0;
                .nav-tabs{
                    li{
                        a{
                            padding: 15px 10px;
                        }
                    }
                }
            }
        }
        .tab-content{
            &.container-fluid{
                padding-left: 0;
                padding-right: 0;
                .tab-pane{
                    .container-fluid{
                        padding-left: 0;
                        padding-right: 0;
                    }
                }
            }
        }
    }
    .modal-default{
        .modal-body{
            h2{
                font-size: 20px;
            }
            .modal-delete{
                dl{
                    &.w-75{
                        width: 90%!important;
                    }
                }
            }
            form{
                .form-group{
                    a{
                        word-break: break-word;
                    }
                }
            }
        }
        .default_form {
            .content-space{
                padding-left: 15px;
            }
        }
        .job-detail-mdl{
            .modal-body{
                .info{
                    border-top: 1px dashed #ddd;
                    border-left: none;
                }
            }
        }
    }
    .modal-delete{
        .modal-header{
            padding: 20px 15px 0 15px;
            i{
                &.icon-delete{
                    font-size: 60px;
                }
            }
        }
        .modal-content{
            h2{
                font-size: 1.2rem;
            }
        }
        .modal-footer{
            padding: 0 20px 20px;
        }
    }
    .main-filter{
        .main-filter-form {
            h2{
                font-size: 1.4rem;
                padding: 20px 0 20px;
            }
        } 
    }
}

@media (max-width:667px){
    .main-sec{
        .filter_result{
            -ms-flex-wrap: wrap;
            flex-wrap: wrap;
            label{
                margin: 4px 0;
            }
        }
        .col-9, .col-11{
            .pagination {
                flex-direction: column;
                margin-bottom: 15px;
                margin-top: 15px;
                -webkit-box-align: start !important;
                -ms-flex-align: start !important;
                align-items: flex-start !important;
            }
        }
        .claimant-feed{
            .claimant-box{
                .table-footer{
                    -webkit-box-orient: vertical;
                    -webkit-box-direction: normal;
                    -ms-flex-direction: column;
                    flex-direction: column;
                    .table-footer-button-group{
                        -ms-flex-wrap: wrap;
                        flex-wrap: wrap;
                        margin-top: 5px;
                        .btn{
                            padding: 7px 14px;
                            margin: 2px;
                        }
                    }
                }
            }
        }
    }
}

@media (max-width: 575px){
    .main-sec{
        .col-9, .col-11{
            .container-fluid{
                padding: 0;
            }
        }
        .claimant-feed{
            .claimant-box{
                h2{
                    &.name{
                        font-size: 1.4rem;
                    }
                }
                .actions{
                    a{
                        padding: 6px 8px;
                    }
                }
                .infos{
                    margin-bottom: 10px;
                }
                .btn-group, .add-account{
                    margin: 2px 0;
                }
                .lead{
                    font-size: 1rem;
                }
            }
            .claimant-profile-page-container{
                input{
                    height: 42px;
                }
            }
        }
    }
    .modal-default{
        .modal-dialog {
            .modal-header{
                .btn{
                    padding: 6px 8px;
                    font-size: 11px;
                }
            }
            .modal-body{
                .medias{
                    ul{
                        li{
                            .add{
                                text-align: center;
                            }
                        }
                    }
                }
            }
        }
    }
    .scan-pg, .quick-capture, .login, .control-panel-content{
        h1 {
            font-size: 1.6rem;
        }
        .default_form{
            button[type="submit"]{
                margin: 15px 0 0;
            }
        }
        .upload{
            h2 {
                font-size: 1.2rem;
            }
            #drop_zone{
                p{
                    font-size: 1rem;
                }
            }
        }
    }
    .modal-default{
        .modal-body{
            h2{
                font-size: 18px;
            }
            .text-center{
                &.mt-4{
                    .btn, input{
                        margin: 2px 0;
                    }
                }
            }
        }
        #background{
            .claimant-background{
                .claimant-background-content{
                    p{
                        a{
                            word-break: break-word;
                        }
                    }
                }
            }
        }
    }
    .modal-delete{
        .modal-header{
            i{
                &.icon-delete{
                    font-size: 50px;
                }
            }
        }
        .modal-content{
            h2{
                font-size: 1.1rem;
            }
        }
        .modal-footer{
            flex-wrap: wrap;
            .btn{
                margin: 2px 5px;
            }
        }
    }
    .queue_list{
        .new_scan_form{
            .subtitle{
                font-size: 1.2rem;
            }
        }
    }
}

@media (max-width: 415px){
    .modal-default{
        .modal-dialog {
            .modal-body{
                .default_form{
                    .picture_preview{
                        flex-direction: column;
                        align-items: flex-start;
                        label{
                            margin-top: 10px;
                        }
                        .btn{
                            margin-left: 0;
                        }

                    }
                }
            }
        }
    }
    .default_form{
        .picture_preview{
            .d-flex{
                flex-direction: column;
                .btn{
                    margin: 10px 0 10px 0;
                }
            }
        }
    }
    .main-sec{
        .claimant-feed{
            .main-tabbing-btn{
                .text-right {
                    text-align: left!important;
                }
            }
        }
    }
}

@media (max-width: 375px){
    .next-prev-feed{
        text-align: left !important;
    }
}