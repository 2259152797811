html, body {
	border: 0;
	font-family: karla, sans-serif;
	line-height: 1.5;
	margin: 0;
	padding: 0;
  color: #333333;
}

div, span, object, iframe, img, table, caption, thead, tbody,
tfoot, tr, tr, td, article, aside, canvas, details, figure, hgroup, menu,
nav, footer, header, section, summary, mark, audio, video {
  border: 0;
  margin: 0;
  padding: 0;
}

h1, h2, h3, h4, h5, h6, p, blockquote, pre, a, abbr, address, cit, code,
del, dfn, em, ins, q, samp, small, strong, sub, sup, b, i, hr, dl, dt, dd,
ol, ul, li, fieldset, legend, label {
  border: 0;
  //font-size: 100%;
  vertical-align: baseline;
  margin: 0;
  padding: 0;
}

h1,h2,h3,h4,h5,h6 {
  font-family: rubik, sans-serif;
  font-weight: 800;
}

h1 {
  font-size: 2.5rem;
  color:#333333;
  margin-top:30px;
  margin-bottom:15px;
  font-weight: 600;
}

article, aside, canvas, figure, figure img, figcaption, hgroup,
footer, header, nav, section, audio, video {
	display: block;
}

table {
  border-collapse: separate;
  border-spacing: 0;
  caption, th, td {
    text-align: left;
    vertical-align: middle;
  }
}

a img {
  border: 0;
}

:focus {
  //outline: 0;
}

.no-padding {
  padding:0 !important;
}

.no-margin {
  margin:0 !important;
}    

@keyframes spinner-border {
  to { transform: rotate(360deg); }
} 
.spinner-border{
    display: inline-block;
    width: 2rem;
    height: 2rem;
    vertical-align: text-bottom;
    border: .25em solid currentColor;
    border-right-color: transparent;
    border-radius: 50%;
    -webkit-animation: spinner-border .75s linear infinite;
    animation: spinner-border .75s linear infinite;
}
.spinner-border-sm{
    height: 1rem;
    border-width: .2em;
}


.content_scroll {
  color:#333333;
  height: 100px;
  overflow-y: auto;
  padding-right: 5px;
  overflow-x: hidden;      

}

.content_scroll.show  {
  overflow-y: scroll;
}

.content_scroll::-webkit-scrollbar-thumb {
  background-color:#444;
  border-radius: 10px;
 
}

.content_scroll::-webkit-scrollbar {
  width: 5px;
  background: #ccc;
}

.icon-control-panel:before {
  font-size: 128%;
}